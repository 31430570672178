// src/pages/About.js
import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>We currently make software that caters to the residents of The Villages, FL.</p> 
      <p>Our software fits into the category of lifestyle applications.</p>
    </div>
  );
};

export default About;