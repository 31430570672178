import React from 'react';
import guideImage from '../UnofficialGuidetoTheVillages.jpg';
import '../Guide.css'; 

const Guide = () => {
  return (
    <div className="guide-container">
      <h1>Unofficial Guide-The Villages</h1>
      <img src={guideImage} alt="Unofficial Guide to The Villages" className="guide-image" />
      <p>The first application 'Unofficial Guide-The Villages' was released on the Apple App Store 5 months ago. Version 2 will be released soon with support for iOS and Android.</p>
      <div className="download-section">
        <h2>Download from:</h2>
        <ul>
          <li><a href="https://apps.apple.com/us/app/unofficial-guide-the-villages/id6471383224" target="_blank" rel="noopener noreferrer">Apple App Store</a></li>
          <li>Google Play Store (Coming soon)</li>
        </ul>
      </div>
    </div>
  );
};

export default Guide;