import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
// import Applications from './pages/Applications';
import Guide from './pages/Guide';
import PrivacyPolicy from './pages/PrivacyPolicy'; 
// import News from './pages/News';
// import Blog from './pages/Blog';
// import Contact from './pages/Contact';
// import Support from './pages/Support';
import logo from './logo.jpg'; // Ensure the logo is in the src directory
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              {/* <li><Link to="/applications">Applications</Link></li> */}
              <li><Link to="/guide">Unofficial Guide-The Villages</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              {/* <li><Link to="/news">News & Updates</Link></li> */}
              {/* <li><Link to="/blog">Blog</Link></li> */}
              {/* <li><Link to="/contact">Contact Us</Link></li> */}
              {/* <li><Link to="/support">Support</Link></li> */}
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/applications" element={<Applications />} /> */}
            <Route path="/guide" element={<Guide />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* <Route path="/news" element={<News />} /> */}
            {/* <Route path="/blog" element={<Blog />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/support" element={<Support />} /> */}
          </Routes>
        </main>
        <footer>
        <p>Connect with us on <a href="https://www.facebook.com/profile.php?id=61554370110814" target="_blank" rel="noopener noreferrer">Facebook</a></p>
        </footer>
      </div>
    </Router>
  );
}

export default App;